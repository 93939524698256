.playing {
    background: transparent;
    width: 30px;
    height: 30px;
    border-radius: .3rem;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: .5rem;
    box-sizing: border-box;
    text-align: center;
    margin-left: 10px;
  }
  
  .playing__bar {
    display: inline-block;
    background: #38B6FF;
    width: 20%;
    height: 100%;
    animation: up-and-down 1.3s ease infinite alternate;
  }
  
  .playing__bar1 {
    height: 60%;
  }
  
  .playing__bar2 {
    height: 30%;
    animation-delay: -2.2s;
  }
  
  .playing__bar3 {
    height: 75%;
    animation-delay: -3.7s;
  }
  
  @keyframes up-and-down {
    10% {
      height: 30%;
    }
    
    30% {
      height: 100%;
    }
    
    60% {
      height: 50%;
    }
    
    80% {
      height: 75%;
    }
    
    100% {
      height: 60%;
    }
  }
  
 